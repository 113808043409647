import { BROWN_HEADING } from "../../consts/colors";
import { commonStyles } from "../../assests/commonStyles";

export const styles = {
  modal: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: "12px",
      overflowX: "hidden",
      ...commonStyles.customSrollBar,
    },
  },

  largeModalStyle: {
    "& .MuiPaper-root": {
      borderRadius: "12px",
      overflowX: "hidden",
      ...commonStyles.customSrollBar,
      // height:"80vh",
    },
  },
  buttonContainer: {
    position: "absolute",
    right: "0",
    top: "2%",
    zIndex:"2"
  },
  btnOnExtremeRightStyles: {
    display: "flex",
    justifyContent: "end",
    paddingTop: "0",
    minWidth: "auto",
  },
  closeIcon: {
    fill: BROWN_HEADING,
  },
};
