import {
  APP_PRIMARY_THEME_COLOR,
  BRIGHT_RED,
  DIMGREY,
  DIM_GREY,
  GREEN_TEAL,
  GREY77,
  GREY95,
  GREY_CHATEAU,
  SKY_BLUE,
  TEXT_RED,
  TRANSPARENT_GREY,
} from "../../consts/colors";

export const styles = {
  tableHeader: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    color: DIM_GREY,
    textAlign: "left",
  },
  activeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  sucess: {
    backgroundColor: GREEN_TEAL,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  },
  warning: {
    backgroundColor: DIMGREY,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  },
  danger: {
    backgroundColor: BRIGHT_RED,
    height: "12px",
    width: "12px",
    borderRadius: "50%",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    padding: "16px 28px 16px, 20px",
  },
  actionItems: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  actionIcon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
    color: GREY77,
    "&:hover": {
      color: APP_PRIMARY_THEME_COLOR,
    },
  },
  tableParentContainer: {
    width: "100%",   
  },
  tableOuterContainer: {
    width: "100%",
    overflowX: "auto",
    maxHeight: "calc(100vh - 280px)",
    borderRadius: "8px",
    opacity: "0.9",
    border: `1px ${GREY95}`,
  },
  tableContainer: {
    overflowX: "auto",
    maxWidth: "100%",
    overflowY: "auto",
  },
  outerFilterContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "start",
  },
  labelStyle: {
    color: APP_PRIMARY_THEME_COLOR,
    fontSize: "12px",
    lineHeight: "18px",
  },
  headerFilter: {
    display: "flex",
    alignItems: "center",
    gap: "0px",
  },
  arrowUpdward: {
    height: "18px",
    width: "14px",
    cursor: "pointer",
  },
  arrowDownward: {
    height: "18px",
    width: "14px",
    cursor: "pointer",
  },
  evenRow: {
    backgroundColor: TRANSPARENT_GREY,
  },
  pointer: {
    cursor: "pointer",
  },
  largerCellContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  largerText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  arrow: {
    height: "18px",
    width: "14px",
    color: GREY_CHATEAU,
    fontWeight: "normal",
    cursor: "pointer",
  },
  emptyTableContainer: {
    display: "contents",
    width: "100%",
  },
  greenArrow: {
    color: GREEN_TEAL,
    fontWeight: "600",
  },
  tableRow: {
    "&:hover": {
      backgroundColor:SKY_BLUE,
    },
    verticalAlign: 'middle'
    
  },
  tableCell: {
    wordBreak: "break-word",
    textAlign: "left",
  },
  capitalizeText: {
    textTransform: "capitalize",
  },
  imageStyle: {
    // width: "70px",
    minHeight: "65px",
    objectFit:"contain",
    width:'100px'
  },
  ellipsis: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkBoxStyle:{
    '& .MuiSvgIcon-root': {
      color: APP_PRIMARY_THEME_COLOR,
    },
  },
  input: {
    "& .MuiInputBase-root": {
      height: "45px",
      fontSize:"14px",
      borderRadius: "5px",
      "& fieldset": {
        border: "1.5px solid lightgrey",
      },
      "&.Mui-focused fieldset": {
        border: `1.5px solid  ${APP_PRIMARY_THEME_COLOR}`,
      },
      "&:hover fieldset": {
        border:` 1.5px solid  lightgrey`,
      },
    },
    '& p':{
      color:'red',
    },
  },
  selectInput: {
    height: "45px",
    fontSize:"14px",
    borderRadius: "5px",
    textTransform: "capitalize",
    "&:hover fieldset": {
      border: `none`,
    },
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1.5px solid lightgrey",
      },
      "&.Mui-focused fieldset": {
        border: `1.5px solid  ${APP_PRIMARY_THEME_COLOR}`,
      },
    },
  },
  validityError: {
    fontSize: "12px",
    color: TEXT_RED,
  },
  errorContainer:{
    minHeight:"20px"
  },
  
};
