import { FADED_GREY, GREY77, RED_RATING, WHITE } from "../../consts/colors";

const styles = {
  cropParentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    height: 'auto',
    width: '100%'
  },
  cropperContainer: {
    position: 'relative',
    width: '100%',
    height: '45vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'grab',
    backgroundColor: GREY77,
    '& .reactCrop': {
      height: '100%',
      width: 'auto',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '&:focus': {
        outline: 'none'
      }
    },
    '& .reactCrop__image': {
      transformOrigin: 'center center',
      pointerEvents: 'none',
      height: '100%',
      width: 'auto',
      maxWidth: 'none',
      userSelect: 'none'
    },
    '& .reactCrop__crop-selection': {
      border: '1px solid white',
      boxShadow: '0 0 0 9999em rgba(0, 0, 0, 0.5)',
      borderRadius: '2px',
      zIndex: 1,
      '&.reactCrop__crop-selection--disabled': {
        display: 'block !important'
      }
    }
  },
  actionBtnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "8px",
    marginTop: "32px",
    width:'100%'
  },
  customContainerStyle: {
    wordBreak: "break-word",
    paddingTop: "8px",
  },
  customTextStyle: {
    fontSize: "14px",
    color: RED_RATING,
  },
  buttonStyle: {
    width: "80px",
  },
  additionalStyles: {
    cursor: "not-allowed",
  },
  cropAreaStyle: {
    border: `2px solid ${WHITE}`,
  },
};

export default styles;
