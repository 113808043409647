import React, { useEffect, useMemo, useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CustomButton from "../CustomButton/CustomButton";
import CustomModal from "../CustomModal/CustomModal";
import ZoomSliderWithInfo from "../ZoomSliderWithInfo/ZoomSliderWithInfo";
import getCroppedImg from "../../utils/cropImage";
import { getImageSource } from "../../utils/getImageSource";
import styles from "./CropAndRotateImageStyles";


const CropAndRotateImage = ({
  file,
  errorWhileUpload,
  handleFileUpload,
  heading,
  isLoading,
  initiateFileUpload,
  onClose,
  onSuccess,
  setFile,
  setOpenCropView,
  shouldOpenInModal,
}) => {
  const [crop, setCrop] = useState();
  const [zoom, setZoom] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isCroppingImage, setIsCroppingImage] = useState(false);
  const [cropperSize, setcropperSize] = useState({ width: undefined, height: undefined });
  const imgRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    return () => {
      resetStates();
    };
  }, []);

  const onWheel = (e) => {
    if (!containerRef.current) return;
    
    e.preventDefault();
    const container = containerRef.current;
    const rect = container.getBoundingClientRect();
    
    // Check if mouse is over the container
    if (
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom
    ) {
      const delta = e.deltaY < 0 ? 0.1 : -0.1;
      const newZoom = Math.max(minZoom, Math.min(3, zoom + delta));
      setZoom(newZoom);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', onWheel, { passive: false });
    }
    return () => {
      if (container) {
        container.removeEventListener('wheel', onWheel);
      }
    };
  }, [zoom, minZoom]);

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    setcropperSize({ width, height });
        setZoom(1);
        setCrop({
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      height: 100
    });
  };

  const uploadImageToServer = ({ uploadedFile }) => {
    handleFileUpload({
      file: uploadedFile,
      successCallback: (file) => {
        onSuccess(file);
      },
    });
  };

  const cropImage = async () => {
    try {
      setIsCroppingImage(true);
  
      if (!imgRef.current || !crop) {
        throw new Error("Please select a crop area");
      }
  
      const pixelCrop = {
        x: (crop.x * imgRef.current.naturalWidth) / 100,
        y: (crop.y * imgRef.current.naturalHeight) / 100,
        width: (crop.width * imgRef.current.naturalWidth) / 100,
        height: (crop.height * imgRef.current.naturalHeight) / 100,
      };
  
      const imageSrc = imgRef.current.src; 
      const { file } = await getCroppedImg(imageSrc, pixelCrop, rotation);
  
      if (file) {
        setOpenCropView(false);
        initiateFileUpload({
          onLoad: () => uploadImageToServer({ uploadedFile: file }),
          uploadedFile: file,
        });
      }
  
      setIsCroppingImage(false);
    } catch (error) {
      console.error("Error cropping image:", error);
      setIsCroppingImage(false);
    }
  };
  
  const cancelCropHandler = () => {
    setOpenCropView(false);
    setFile(null);
    onClose && onClose();
  };

  const resetStates = () => {
    setCrop(undefined);
    setIsCroppingImage(false);
  };

  const fileName = useMemo(() => {
    return getImageSource(file)
  },[file])

  const aspectRatio = useMemo(() => {
    if (cropperSize.width && cropperSize.height) {
      return cropperSize.width / cropperSize.height;
    } else {
      return 1; 
    }
  }, [cropperSize]);

  const renderModalContent = () => {
    return (
      <Box sx={styles.cropParentContainer}>
        <Box 
          ref={containerRef}
          sx={styles.cropperContainer}
        >
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            minHeight={10}
            minWidth={10}
            className="reactCrop"
            keepSelection={true}
            ruleOfThirds
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={fileName}
              style={{ 
                transform: `scale(${zoom}) rotate(${rotation}deg)`,
                height: '45vh',
                width: 'auto',
                objectFit: 'contain',
                transition: 'transform 0.1s ease',
                transformOrigin: 'center',
                maxWidth: 'none'
              }}
              onLoad={onImageLoad}
              draggable={false}
            />
          </ReactCrop>
        </Box>
        <ZoomSliderWithInfo 
          {...{ 
            zoom,
            setZoom,
            setRotation,
            rotation
          }} 
        />
        {!!errorWhileUpload && (
          <Typography
            fontWeight="600"
            sx={{ ...styles.customTextStyle, ...styles.customContainerStyle }}
          >
            {errorWhileUpload}
          </Typography>
        )}
        <Box sx={styles.actionBtnContainer}>
          <CustomButton
            onClick={
              isCroppingImage || isLoading ? () => {} : ()=>cancelCropHandler()
            }
            style={{
              ...styles.buttonStyle,
              ...(isCroppingImage || isLoading ? styles.additionalStyles : {}),
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            isLoading={isCroppingImage || isLoading}
            onClick={()=>cropImage()}
            style={styles.buttonStyle}
            withGreenBackground
          >
            <Typography>Save</Typography>
          </CustomButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {shouldOpenInModal ? (
        <CustomModal
          openModal={shouldOpenInModal}
          closeModalHandler={() => cancelCropHandler()}
          maxWidth="sm"
          fullWidth
          {...{ heading }}
        >
          {renderModalContent()}
        </CustomModal>
      ) : (
        renderModalContent()
      )}
    </>
  );
};

CropAndRotateImage.defaultProps = {
  file: null,
  errorWhileUpload: "",
  handleFileUpload: () => {},
  heading: "Edit Picture",
  isLoading: false,
  initiateFileUpload: () => {},
  onClose: () => {},
  onSuccess: () => {},
  photoURL: "",
  setFile: () => {},
  setOpenCropView: () => {},
  shouldOpenInModal: true,
};

CropAndRotateImage.propTypes = {
  file: PropTypes.object,
  errorWhileUpload: PropTypes.string,
  handleFileUpload: PropTypes.func,
  heading: PropTypes.string,
  isLoading: PropTypes.bool,
  initiateFileUpload: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  photoURL: PropTypes.string,
  setFile: PropTypes.func,
  setOpenCropView: PropTypes.func,
  setPhotoURL: PropTypes.func,
  shouldOpenInModal: PropTypes.bool,
};

export default CropAndRotateImage;
