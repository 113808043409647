// API VERSION NUMBER
export const API_VERSION_NUMBER = "v4";

/** API Endpoints */
export const GET_SAAS_ADMIN_LIST = "api/saas";
export const GET_SAAS_ADMIN_PAYMENT_SUMMARY_LIST = "api/saas/payments/summary";
export const GET_SASS_PAYMENT = "api/saas/payments";
export const GET_UPLOAD_URL = "signature";
export const SESSION = "session";
export const UPDATE_SASS_PAYMENT = "api/saas/payments";
export const RESET_PASSWORD_URL = "api/public/auth/reset-token-verification";
export const GET_USER_LIST = "api/users/admin";
export const UPDATE_USER = "api/users/admin";
export const CREATE_USERS = "api/users/admin";
export const GET_ADMIN_LIST = "api/users/admin";
export const CATEGOIRES_NOT_NESTED = "api/categories";
export const DENTIST_PROFILE = "/api/users/dentist";
export const ADMIN_PROFILE = "/api/users/admin";
export const SUB_CATEGORIES_LIST = "api/subcategories";
export const MARK_FAVOURAITE = "api/dentist/favourite";
export const NOTES = "api/dentist/notes";
export const CHANGE_PASSWORD = "api/auth/change-pwd";
export const ACCOUNTS = "api/users/dentist";

export const editAccount = "api/users/dentist";
export const LOGOUT = "api/public/auth/logout";
export const CATEGORIES = "api/categories?fetchNested=true";
export const FORGOT_PSWD = "/api/public/auth/forgot-pwd";
export const EMAIL_VALIDATE = "api/public/auth/check-email";
export const COURSES = "/api/courses";
export const SUB_CATEGORIES = "api/categories";
export const REARRANGE_COURSES = "api/courses/rearrange";
export const COURSEBY_CATEGORY = "api/courses/categories";
export const REARRANGE_CATEGORY = "api/categories/rearrange";
export const SEND_CREDS = "api/send/cred";
export const SAAS_CSV = "api/saas/export";
export const CREATE_ACCOUNT_BY_PROFILE = "api/dentist/authorizenet-id";
export const ADMIN_MANAGE_SUBSCRIPTION = "api/subscriptions";
export const DENTIST_SUBSCRIPTION = "api/dentist/subscriptions";
export const DENTIST_COURSES = "api/courses/categories";
export const UPLOAD_IMAGE_QUESTIONNAIRE = "api/upload/image";
export const SUBSCRIPTIONCSV = "api/subscriptions/export";
export const CREATE_SUBSCRIPTION = "api/subscriptions/create";
export const CANCEL_SUBSCRIPTION = "api/subscriptions/cancel";
export const REFRESH_TOKEN = "api/public/refresh/token";
export const FILE_UPLOAD = "api/file/upload";
export const PROGRAM_REGISTER="api/dentist/payment/profile";
export const CE_REQUEST = "api/request/ceCredit";
export const PURCHASE_PROGRAM = "api/dentist/purchase/program";